<template>
  <div class="audio-track d-flex justify-content-between py-2" v-if="item.audio">
    <div class="flex-grow-1">
      <p class="text-secondary uploaded">
        Uploaded by {{ item.owner }}
      </p>
      <div class="">
        <p class="name-audio">{{ item.file.split("/media/")[1] }}</p>
        <div class="audio">
          <av-waveform
            :line-width="2"
            :audio-src="item.audio"
            :canv-height="30"
            canv-class="canvas d-none"
            :progress-color="color"
          ></av-waveform>
        </div>
      </div>
    </div>
    <button
      @click="onDelete"
      class="manage-audio-btn text-primary p-2 d-flex align-items-end"
    >
      <i class="mdi mdi-delete h3" title="Delete"></i>
    </button>
  </div>
</template>


<script>
// import Create from '@/components/CreateModal'
export default {
  components: {
    // Create
  },
  props: ["item"],
  data() {
    return {
      modalId: "modal-2",
      color: "#fff",
    };
  },
  methods: {
    onDelete() {
      this.$emit("delete");
    },
  },
};
</script>
<style lang="scss">
audio {
  width: 100%;
  max-height: 35px;
}
</style>