<template>
  <div>
    <slot :selectedGroup="selectedGroup"></slot>
  </div>
</template>
<script>
export default {
  props: ["selectedGroup", "ani"],
  methods: {
    async uploadAudio(data) {
      if (!data.fileName && data.type == 3) {
        this.$swal.fire(
          "Give the file a name",
          "You need to give a unique name to the file",
          "error"
        );
      } else {
        let formData = new FormData();
        if (data.type == 3) {
          const item = data.file;
          data.file = new File([item], `${data.fileName}.mp3`);
        }

        formData.append("file", data.file);

        await this.$axios
          .post("/media/upload", formData)
          .then(async (res) => {
            let id = res.data.uuid ? res.data.uuid : 1;
            if (id !== 1) {
              this.$emit("id", id);
            }

            this.$store.dispatch("loadFiles");
            await this.$swal.fire(
              "File uploaded!",
              "The file has been uploaded to the server!",
              "success"
            );
          })
          .catch(async (err) => {
            this.$emit("error", true);
            await this.$swal.fire("Error", err.response.data.error, "error");
          });
      }
    },
    async uploadGroups(item) {
      let formData = new FormData();
      if (item.groups) {
        if(!this.ani) formData.append("group", item.groups);
        else formData.append("ani_groups", item.groups);
      }
      formData.append("file", item.file);
      if (this.selectedGroup) {
        if(!this.ani)formData.append("group", this.selectedGroup.name);
        else formData.append("ani_groups", this.selectedGroup.name);
      }
      this.$swal.fire({
        title: "Uploading data",
        onOpen: () => {
          this.$swal.showLoading()
        }
      })
      const url = this.ani ? "/ani_groups/upload" : "/contacts/upload";
      var _self = this
      _self.$emit("showProgress", true)
      await this.$axios
        .post(url, formData, {
            onUploadProgress: function(progress) {
              _self.$emit("updateProgress", Math.round((progress.loaded / progress.total) * 100))
            }
          }
        )
        .then(async (res) => {
          this.$emit("upload");
          setTimeout(() => _self.$emit("showProgress", false), 3000);
          await this.$swal.fire(
            "File uploaded!",
            res.data.message,
            "success"
          );
        })
        .catch(async (err) => {
          this.$emit("error", true);
          this.$emit("close");
          setTimeout(() => _self.$emit("showProgress", false), 3000);
          await this.$swal.fire("Error", err.response.data.error, "error");
        });
    },
  },
};
</script>
