<template>
  <div class="page">
    <div class="audio-manage">
      <div class="row">
        <div class="col-12 d-flex align-start">
          <button
            v-b-modal.modal-2
            class="btn btn-primary btn-voice-record d-flex justify-content-center align-items-center shadow mb-3"
          >
            <div class="text-center">
              <p
                class="font-weight-light m-0 audio-text text-white font-weight-bold"
              >
                Add audio
              </p>
            </div>
          </button>
          <b-form-group
            class="select col-3 align-center ml-auto mb-0 pl-0"
            label-for="input11"
          >
            <b-form-input
              placeholder="Search Audio"
              v-model="search"
              size="sm"
              style="height: 35px"
            />
          </b-form-group>
        </div>
        <template v-if="searchItems">
          <span class="col-12" v-if="!$store.state.loading && audios.length == 0"
            >*Audio: 0.<br />
            Use the "Add audio" button to add audio.
          </span>
          <div class="col-12 mt-2" v-if="!$store.state.loading && searchItems && searchItems.length == 0">
            Zero matches, try another name
          </div>
          <div class="col-12" v-else>
            <div class="manage-audios-wrap shadow-sm p-3 px-md-5 bg-white">
              <template>
                <audioTrack
                  v-for="(item, index) in searchItems"
                  :key="index + 'a'"
                  :item="item"
                  @delete="deleteConfirm(item.media_id)"
                />
              </template>
            </div>
          </div>
        </template>
      </div>
      <UploadWrapper v-if="audios">
        <Create
          :modalId="modalId"
          :modalSize="modalSize"
          :audios="audios"
          ref="create"
        />
      </UploadWrapper>
    </div>
  </div>
</template>

<script>
// import vueRecordAudio from
import audioTrack from "@/components/audioTrack";
import Create from "@/components/CreateModal";
import { mapGetters } from "vuex";
import UploadWrapper from "@/components/UploadWrapper.vue";

export default {
  components: {
    audioTrack,
    Create,
    UploadWrapper,
  },
  data() {
    return {
      file: null,
      modalId: "modal-2",
      modalSize: "sm",
      search: '',
      items: null,
    };
  },
  computed: {
    ...mapGetters(["audios", "admin"]),
    searchItems() {
      let audios = this.audios;
      let items;
      if (this.search !== "" && this.search.length > 2) {
        items = audios.filter((el) => el.text.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
      } else items = audios;
      return items
    },
  },
  async mounted() {
    this.$store.commit("updateLoading", true);
    await this.$store.dispatch("loadFiles");
    const name = await this.$route.params.name;
    if (name) this.search = name;
  },
  methods: {
    deleteConfirm(media_id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteFile(media_id);
          }
        });
    },
    deleteFile(media_id) {
      this.$axios
        .post(`${this.admin}/media/delete/${media_id}`)
        .then(() => {
          this.$store.dispatch("loadFiles");
          this.$swal.fire(
            "Deleted!",
            "Your audio has been deleted.",
            "success"
          );
        })
        .catch((err) =>
          this.$swal.fire("Error", err.response.data.error, "error")
        );
    },
  },
};
</script>

<style lang="scss">
.audio-manage {
  .audio-track:not(:last-child) {
    border-bottom: 1px solid #d0d4dd;
  }
  .btn-voice-record {
    &:focus-visible {
      outline: none;
    }
  }
  .name-audio {
    font-size: 0.8rem;
    font-weight: normal;
  }
  .uploaded {
    font-size: 0.65rem;
  }
  .manage-audio-btn {
    border: none;
    background: transparent;
    min-width: 30px;
  }
  audio {
    font-size: 0.65rem;
  }
}
</style>
