<template>
    <b-modal
      :id="modalId"
      :title="title"
      :size="modalSize"
      @ok="submitForm(type)"
    >
      <template v-if="modalType == 1">
        <b-form-group label="A filter phone number" label-for="input11">
          <b-form-select :options="optionsInput1" v-model="filter" />
        </b-form-group>

        <b-form-group label="Select a form phone number" label-for="input11">
          <b-form-select :options="optionsInput1" v-model="phone" />
        </b-form-group>
      </template>

      <!-- <b-form-radio-group id="radios2" name="radioSubComponent"> -->
      <div class="d-flex">
        <b-form-radio
          v-for="item in radios"
          :value="item.id"
          :key="item.id"
          v-model="type"
          @change="deleteAudio"
          class="radio"
          >{{ item.label }}</b-form-radio
        >
        <b-form-radio
          v-if="isCanSelect"
          :value="radioSelectRecords.id"
          v-model="type"
          @change="deleteAudio"
          class="radio"
        >
          {{ radioSelectRecords.label }}
        </b-form-radio>
      </div>
      <b-form-group
        class="mt-2"
        label="File name"
        label-for="input2"
        v-if="type == 3"
      >
        <b-form-input type="text" id="input2" v-model="fileName"></b-form-input>
      </b-form-group>
      <!-- </b-form-radio-group> -->
      <div class="content">
        <b-form-group label="Upload file" label-for="input8" v-if="type == 1">
          <b-form-file
            v-model="file"
            id="inpu8"
            accept=".wav, .mp3"
            :state="file ? Boolean(file) : null"
            placeholder="Choose a file..."
          ></b-form-file>
        </b-form-group>

        <b-form-group label="Select audio" label-for="input11" v-if="type == 2">
          <b-form-select
            :options="selectAudio"
            placeholder="Select audio"
            v-model="selectedAudio"
            @change="updateAudio(selectedAudio.audio)"
          />
        </b-form-group>

        <div
          class="d-flex align-items-center"
          v-if="type == 3"
          @click="deleteAudio"
        >
          <!-- <VueRecordAudio @result="onResult" /> -->
          <audio-recorder
            :attempts="1"
            :before-recording="recordAudio"
            :pause-recording="recordAudio"
            :after-recording="recordAudio"
            :select-record="recordAudio"
            :before-upload="recordAudio"
            :successful-upload="recordAudio"
            :failed-upload="recordAudio"
          />
          <!-- <span class="audio-text">To start recording press the button</span> -->
        </div>
        <template v-if="modalType == 1">
          <div class="shedule">
            <span>Shedule this call</span>

            <b-form-radio-group
              id="radios2"
              name="radioSubComponent"
              @change="$event == 1 ? (showDate = true) : (showDate = false)"
            >
              <b-form-radio value="0">Now</b-form-radio>
              <b-form-radio value="1">Later</b-form-radio>
            </b-form-radio-group>
          </div>
          <b-form-group
            label="Select date and time"
            class="date"
            v-if="showDate"
          >
            <datetime v-model="date" type="datetime" :auto="true"></datetime>
          </b-form-group>
        </template>
      </div>
    </b-modal>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
  components: {
    datetime: Datetime,
  },
  props: {
    modalType: {
      default: 2,
    },
    title: {
      default: "Ringing Voicemail Drop",
    },
    modalId: {
      type: String,
    },
    modalSize: {
      default: "lg",
    },
    isCanSelect: {
      default: false,
    },
    audios: {
      default: [],
    },
  },
  data() {
    return {
      optionsInput1: ["1", "2", "3"],
      phone: null,
      fileName: null,
      filter: null,
      radios: [
        { id: 1, label: "Upload Audio" },
        { id: 3, label: "Record Audio" },
      ],
      radioSelectRecords: {
        id: 2,
        label: "Select from recordings",
      },
      selectAudio: [
        { id: 1, text: "Track 1", value: "/test.mp3" },
        { id: 2, text: "Track 2", value: "/test.mp3" },
        { id: 3, text: "Track 3", value: "/test.mp3" },
      ],
      type: 1,
      selectedAudio: null,
      file: null,
      audio: null,
      showAudio: false,
      showDate: false,
      date: null,
    };
  },
  mounted() {
    this.date = new Date().toString();
  },
  methods: {
    recordAudio(data) {
      this.file = data.blob;
    },
    deleteAudio() {
      this.showAudio = false;
    },
    updateAudio(file) {
      this.deleteAudio();
      setTimeout(() => {
        this.audio = file;
        this.showAudio = true;
      }, 50);
    },
    submitForm(type) {
      if (type !== 2) {
        this.submit();
      }
    },
    submit() {
      const emit = {type: this.type, file: this.file, fileName: this.fileName}
      this.$parent.uploadAudio(emit)
    },
  },
};
</script>

<style lang="scss" scoped>
input.form-control {
  max-height: 33px;
  padding-top: 0;
  padding-bottom: 0;
}
.audio {
  max-width: 300px;
}

.audio-text {
  margin-left: 8px;
}
.content,
.date,
.shedule {
  margin-top: 1rem;
}
.shedule {
  span {
    margin-bottom: 0.5rem;
    display: block;
  }
}
.radio {
  margin-right: 1rem;
}
</style>

<style lang="scss">
.canvas {
  width: 100% !important;
}

.modal-dialog {
  height: 100%;
  margin-top: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.modal-content {
  max-width: 600px;
  margin: 0 auto;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-footer {
  background: none;
}
.custom-control-label::after,
.custom-control-label::before {
  top: -0.1rem !important;
}
.date .col-form-label {
  padding-bottom: 0.5rem;
}
.custom-select {
  font-size: 13px;
}
svg {
  vertical-align: top;
}
.modal-sm {
  max-width: 370px;
  margin: 0 auto;
}
</style>

<style>
.vue-audio-recorder {
  width: 44px;
  height: 44px;
}
</style>
